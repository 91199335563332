var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "center" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": _vm.$t(`cip.plat.wfOps.ops.title.indexHeadTitle`),
        },
        on: {
          "head-tk": function ($event) {
            return _vm.handleCompleteTask(null, true)
          },
          "head-bh": function ($event) {
            return _vm.handleCompleteTask(null, false)
          },
          "head-bgshr": function ($event) {
            return _vm.handleUserSelect({
              type: "assignee",
              checkType: "radio",
            })
          },
          "head-zb": function ($event) {
            return _vm.handleUserSelect({
              type: "transfer",
              checkType: "radio",
            })
          },
          "head-wt": function ($event) {
            return _vm.handleUserSelect({
              type: "delegate",
              checkType: "radio",
            })
          },
          "head-cs": function ($event) {
            return _vm.handleUserSelect({ type: "copy", checkType: "checkbox" })
          },
          "head-cb": function ($event) {
            return _vm.handleUrgeTask(null)
          },
          "head-zz": function ($event) {
            return _vm.handleTerminateProcess(null)
          },
        },
      }),
      _c("grid-head-layout", {
        ref: "gridHeadLayout",
        attrs: { "search-columns": _vm.searchColumns },
        on: {
          "grid-head-search": _vm.gridHeadSearch,
          "grid-head-empty": _vm.gridHeadEmpty,
        },
      }),
      _c("grid-layout", {
        ref: "gridLayOut",
        attrs: {
          "table-options": _vm.option,
          "table-data": _vm.data,
          "table-loading": _vm.loading,
          "data-total": _vm.page.total,
          "grid-row-btn": _vm.gridRowBtn,
          page: _vm.page,
        },
        on: {
          "page-current-change": _vm.onLoad,
          "page-size-change": _vm.onLoad,
          "page-refresh-change": _vm.onLoad,
          "row-flow": _vm.handleFlow,
        },
      }),
      _vm.bpmnVisible
        ? _c(
            "CommonDialog",
            {
              attrs: {
                customClass: "wf-dialog",
                width: "50%",
                showBtn: false,
                dialogTitle: _vm.$t(
                  `cip.plat.wfOps.ops.title.flowChartHeadTitle`
                ),
              },
              on: {
                cancel: function ($event) {
                  _vm.bpmnVisible = false
                },
              },
            },
            [
              _c("wf-design", {
                ref: "bpmn",
                staticStyle: { height: "500px" },
                attrs: { options: _vm.bpmnOption },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("user-select", {
        ref: "user-select",
        attrs: { "check-type": _vm.checkType },
        on: { onConfirm: _vm.handleUserSelectConfirm },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.nodeVisible,
            "append-to-body": "",
            title: _vm.$t(`cip.plat.wfOps.ops.title.selectHeadTitle`),
          },
          on: {
            "update:visible": function ($event) {
              _vm.nodeVisible = $event
            },
          },
        },
        [
          _vm.nodeVisible
            ? _c("avue-form", {
                attrs: { option: _vm.nodeOption },
                on: { submit: _vm.handleNodeSubmit },
                model: {
                  value: _vm.nodeForm,
                  callback: function ($$v) {
                    _vm.nodeForm = $$v
                  },
                  expression: "nodeForm",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            visible: _vm.detailVisible,
            title: _vm.form.processDefinitionName,
            "custom-class": "wf-drawer",
            size: "60%",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailVisible = $event
            },
          },
        },
        [
          _vm.detailVisible
            ? _c("task-detail", {
                attrs: {
                  taskId: _vm.form.taskId,
                  processInstanceId: _vm.form.processInstanceId,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }